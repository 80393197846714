import {
  PostTypeInProject,
  urlPrefixMap,
} from "../../../../config/wordPressConfig";
import { MinmalPostInformation } from "./createWordPressPages/createPagesForPostTypes/createPagesForPostTypeQuery";

export const pathForSinglePost = (
  postType: PostTypeInProject,
  slug: MinmalPostInformation["slug"]
): string => {
  const prefix = urlPrefixMap[postType];

  const urlComponents: string[] = [];

  if (prefix) {
    urlComponents.push(prefix);
  }

  urlComponents.push(slug);

  return `/${urlComponents.join("/")}`;
};
