import { graphql, useStaticQuery } from "gatsby";

export const useWpVitisSettings =
  (): GatsbyTypes.WpVitisSettingsQuery["allWp"]["nodes"][0]["vitisEinstellungen"] => {
    const settings = useStaticQuery<GatsbyTypes.WpVitisSettingsQuery>(graphql`
      query WpVitisSettings {
        allWp {
          nodes {
            vitisEinstellungen {
              vitisFields {
                companyName
                slogan
                handwrittenSlogan
                address {
                  street
                  streetNumber
                  postalCode
                  city
                }
                contact {
                  contactAvatarImage {
                    ...WpMediaBaseFields
                    ...WpMediaItemImageBackground
                  }
                  landlinePhoneNumber
                  mobilePhoneNumber
                  emailAddress
                  linkedinLink
                  xingLink
                }
              }
            }
          }
        }
      }
    `);

    return settings.allWp.nodes[0].vitisEinstellungen;
  };
