import { Container, Grid, Link } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import useInView from "react-cool-inview";
import { useAllWordPressFooterMenuItems } from "../../../queries/wpMenuItems/footer/useAllWordPressFooterMenuItems";
import { useAllWordPressMenuItems } from "../../../queries/wpMenuItems/header/useAllWordPressMenuItems";
import VTSFloatingCommunicationBubble from "../../01-atoms/VTSFloatingCommunicationBubble/VTSFloatingCommunicationBubble";
import { useVTSFooterNavBarStyles } from "./VTSFooterNavBarStyles";

const VTSFooterNavBar = (): JSX.Element => {
  const classes = useVTSFooterNavBarStyles();

  const wpMenuItems = useAllWordPressMenuItems();
  const wpFooterItemsExtra = useAllWordPressFooterMenuItems();

  const footerItems = useMemo(
    () =>
      wpMenuItems.map((item) => (
        <Grid item={true} key={item.id}>
          <Link
            key={item.id}
            href={item.path}
            variant="body1"
            color="textPrimary"
            underline="none"
          >
            {item.label}
          </Link>
        </Grid>
      )),
    [wpMenuItems]
  );

  const footerItemsExtra = useMemo(
    () =>
      wpFooterItemsExtra.map((item) => (
        <Grid item={true} key={item.id}>
          <Link
            href={item.path}
            variant="body1"
            color="textPrimary"
            underline="none"
          >
            {item.label}
          </Link>
        </Grid>
      )),
    [wpFooterItemsExtra]
  );

  const { observe, inView, unobserve } = useInView<HTMLDivElement>({
    threshold: 0.5,
  });

  useEffect(() => {
    return () => {
      unobserve();
    };
  }, [unobserve]);

  return (
    <>
      <Container className="noMargin">
        <div ref={observe}>
          <Grid container={true} className={classes.navBar}>
            <Grid
              item={true}
              md={8}
              container={true}
              className={classes.leftColumn}
            >
              {footerItems}
            </Grid>

            <Grid
              item={true}
              md={4}
              container={true}
              className={classes.rightColumn}
            >
              {footerItemsExtra}
            </Grid>
          </Grid>
        </div>
      </Container>

      <VTSFloatingCommunicationBubble inView={inView} />
    </>
  );
};

export default VTSFooterNavBar;
