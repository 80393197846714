import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useWpVitisSettings } from "../../../queries/wpVitisSettings/useWpVitisSettings";
import { VitisLogoBackground } from "../../../__generated__/svgComponents/VitisLogoBackground";
import { SanitizeHTML } from "../../01-atoms/SanitizeHTML/SanitzeHTML";
import VTSLogoLink from "../../01-atoms/VTSLogoLink/VTSLogoLink";
import VTSUnderlinedTitle from "../../02-molecules/VTSUnderlinedTitle/VTSUnderlinedTitle";
import { useVTSFooterStyles } from "./VTSFooterStyles";

const VTSFooter = (): JSX.Element => {
  const classes = useVTSFooterStyles();
  const { t } = useTranslation();

  const infoData = useWpVitisSettings()?.vitisFields;

  const address = infoData?.address;
  const contact = infoData?.contact;
  const slogan = infoData?.slogan;
  const handWrittenSlogan = infoData?.handwrittenSlogan;

  const street = `${address?.street} ${address?.streetNumber}`;
  const city = `${address?.postalCode} ${address?.city}`;
  const telephone = contact?.landlinePhoneNumber;
  const mobile = contact?.mobilePhoneNumber;
  const email = contact?.emailAddress;

  return (
    <Grid className={classes.footer}>
      <Card className={classes.card}>
        <VTSLogoLink />
      </Card>

      <Grid className={classes.info}>
        <VitisLogoBackground className={classes.logoBackground} />

        <Container className={clsx(classes.infoContentWrapper, "noMargin")}>
          <Box className={classes.slogan}>
            <VTSUnderlinedTitle
              titleRaw={slogan || ""}
              typographyProps={{
                color: "textSecondary",
                variant: "h1",
                className: classes.title,
              }}
              component="h2"
            />

            <Typography
              variant="subtitle2"
              component="h3"
              className={classes.handWrittenSlogan}
            >
              <SanitizeHTML html={handWrittenSlogan || ""} />
            </Typography>
          </Box>

          <Grid className={classes.details}>
            <Grid>
              <Typography variant="body2" color="textSecondary">
                <strong>{t("footer.vitisGmbH")}</strong>
              </Typography>

              <Typography variant="body2" color="textSecondary">
                {street}
              </Typography>

              <Typography variant="body2" color="textSecondary">
                {city}
              </Typography>
            </Grid>

            <Grid className={classes.contact}>
              <Typography variant="body2" color="textSecondary">
                <strong>{t("footer.contact")}</strong>
              </Typography>

              <Button
                href={`tel:${telephone}`}
                target="_blank"
                className={classes.link}
              >
                <Typography variant="body2" color="textSecondary">
                  {`${t("footer.tel")}: `}

                  {telephone}
                </Typography>
              </Button>

              {mobile && (
                <Button
                  href={`tel:${mobile}`}
                  target="_blank"
                  className={classes.link}
                >
                  <Typography variant="body2" color="textSecondary">
                    {`${t("footer.mobile")}: `}

                    {mobile}
                  </Typography>
                </Button>
              )}

              <Button
                href={`mailto:${email}`}
                target="_blank"
                className={classes.link}
              >
                <Typography variant="body2" color="textSecondary">
                  {email}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default VTSFooter;
