import React from "react";
import { useSanitizedHtml } from "../../../helpers/clientSide/hooks/useSanitizedHtml/useSanitizedHtml";
import { SanitizeHTMLProps } from "./SanitizeHTMLInterface";

export const SanitizeHTML = (props: SanitizeHTMLProps): JSX.Element => {
  const { html, options, className } = props;
  const sanitizedHtml = useSanitizedHtml({ html, options });

  const Tag = props.tag || "div";

  return (
    <Tag
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      className={className}
    />
  );
};
