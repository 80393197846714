import { graphql, useStaticQuery } from "gatsby";

export const useAllWordPressFooterMenuItemsQuery =
  (): GatsbyTypes.WordpressFooterMenusQuery =>
    useStaticQuery<GatsbyTypes.WordpressFooterMenusQuery>(graphql`
      query WordpressFooterMenus {
        allWpMenuItem(
          filter: { menu: { node: { locations: { eq: GATSBY_FOOTER_MENU } } } }
        ) {
          nodes {
            ...WpMenuItemPostFields
            ...WpMenuItemCategoryFields
          }
        }
      }
    `);
