import { capitalizeFirstLetter } from "../../general/capitalizeFirstLetter";
import { lowerCaseFirstLetter } from "../../general/lowerCaseFirstLetter";

export const postTypeFromArchiveUrl = (url: string): string => {
  const type = url.substring(1, url.length - 1);
  return `Wp${capitalizeFirstLetter(type)}`;
};

export const archiveSlugFromUrl = (url: string): string =>
  `${url.substring(1, url.length - 1)}-archive`;

export const archiveSlugFromPostType = (postType: string): string =>
  `${lowerCaseFirstLetter(postType.substring(2, postType.length))}-archive`;
