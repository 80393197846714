import { throttle } from "lodash";
import { useMemo } from "react";
import { useWindowEvent } from "./useWindowEvent";

const executeCallback = (callback: ScrollPositionCallback): void => {
  const top = document.body.scrollTop || document.documentElement.scrollTop;
  callback(top);
};

export type ScrollPositionCallback = (position: number) => void;

export const useDocumentScrollPosition = (
  callback: ScrollPositionCallback,
  throttleWait = 75
): void => {
  const throttledFn = useMemo(
    () =>
      throttle(() => executeCallback(callback), throttleWait, {
        leading: true,
        trailing: true,
      }),
    [callback, throttleWait]
  );

  useWindowEvent("scroll", throttledFn);
};
