import clsx from "clsx";
import { mergeWith } from "lodash";
import { CanHaveClassName } from "../../interfaces/PropsAndComponents";

/**
 * passing this to _.mergeWith will result in a shallow merge function.
 * It only differs from Object.assign (or _.assign) in the way 'undefined' is handled:
 *
 *  The mergeWith(… ,shallowMerge) approach will **not** overwrite objValues with undefined
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const shallowMerger = (objValue: any, srcValue: any): any | undefined => {
  if (typeof objValue === "object") {
    return srcValue;
  }
  return undefined;
};

/**
 * Merges a set of default values with values passed as props.
 * Additionally, a className (e.g. from `makeStyles`) can be passed.
 * While props will override defaults, classNames will be added.
 *
 * This has been wrapped in a hook (useMergedProps.ts)
 */
export function mergeDefaultPropsAndClass<T extends CanHaveClassName>(
  defaultProps: Partial<T> | undefined,
  props: Partial<T> | undefined,
  className?: string | undefined
): Partial<T> {
  /**
   * While regular props should be overwritten, class names must be added
   *  to the list.
   */
  const classNameMerged = clsx(
    defaultProps?.className,
    props?.className,
    className
  );

  return mergeWith(
    {},
    defaultProps,
    props,
    { className: classNameMerged },
    shallowMerger
  );
}
