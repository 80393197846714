import { ReactNode } from "react";

/**
 * react's FunctionComponent type is somewhat flawed and shouldn't be used.
 * (@see https://github.com/facebook/create-react-app/pull/8177)
 *
 * This is our own replacement.
 */
export type NVONFunctionComponent<PropsType> = (
  props: PropsType
) => JSX.Element;

export interface CanHaveClassName {
  className?: string;
}

export interface ChildrenAndClassName extends CanHaveClassName {
  children: ReactNode;
}

export type ComponentWithChildrenAndClassName =
  NVONFunctionComponent<ChildrenAndClassName>;

export type ComponentWithClassName = NVONFunctionComponent<CanHaveClassName>;
