import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useVTSSubNavigationStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: theme.palette.secondary.main,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    toolbar: {
      minHeight: theme.spacing(1.6),
      justifyContent: "flex-end",
    },
    links: {
      marginLeft: theme.spacing(1.5),
      color: theme.palette.secondary.light,
    },
    linkText: {
      color: theme.palette.secondary.light,
      fontSize: theme.spacing(0.85),
      textTransform: "none",
      marginLeft: theme.spacing(0.3),
    },
    icon: {
      color: theme.palette.secondary.light,
      width: theme.spacing(1.2),
      height: theme.spacing(1.2),
    },
  })
);
