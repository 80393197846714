import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useMemo } from "react";
import { getUnderlinedBackendTag } from "../../../helpers/clientSide/getUnderlinedBackendTag";
import { replaceTagsWithFunctionComponent } from "../../../helpers/clientSide/replaceTagsWithFunctionComponent";
import { VTSAnimatedUnderline } from "../../01-atoms/VTSAnimatedUnderline/VTSAnimatedUnderline";
import { VTSUnderlinedTitleProps } from "./VTSUnderlinedTitleInterfaces";
import { useVTSUnderlinedTitleStyles } from "./VTSUnderlinedTitleStyles";

const VTSUnderlinedTitle = (props: VTSUnderlinedTitleProps): JSX.Element => {
  const classes = useVTSUnderlinedTitleStyles();

  const underlinedBackendTag = getUnderlinedBackendTag();

  const underlinedTitle = useMemo(() => {
    return (
      props.titleRaw &&
      replaceTagsWithFunctionComponent(
        props.titleRaw,
        props.underlinedBackendTag || underlinedBackendTag,
        VTSAnimatedUnderline
      )
    );
  }, [props.titleRaw, props.underlinedBackendTag, underlinedBackendTag]);

  return (
    <Typography
      {...props.typographyProps}
      component={props.component || "span"}
      className={clsx(props.typographyProps.className, classes.wrapper)}
    >
      {underlinedTitle}
    </Typography>
  );
};

export default VTSUnderlinedTitle;
