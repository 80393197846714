import { useMemo } from "react";
import { mapQueriedMenuItem } from "../../../helpers/clientSide/WordPress/mapQueriedMenuItem";
import { MenuItemType } from "../../../interfaces/MenuItemType";
import { sortMenuItem } from "../sortMenuItem";
import { WordPressMenuItem } from "../WordPressMenuItem";
import { useAllWordPressMenuItemsQuery } from "./useAllWordPressMenuItemsQuery";

export const useMenuItems = (
  menuItemType: MenuItemType
): WordPressMenuItem[] => {
  const allMenuItems = useAllWordPressMenuItemsQuery();

  const topMenuItems = useMemo(
    () =>
      allMenuItems.allWpMenuItem.nodes.filter((item) =>
        menuItemType === MenuItemType.Sub ? item.parentId : !item.parentId
      ),
    [allMenuItems.allWpMenuItem.nodes, menuItemType]
  );

  return useMemo(
    () =>
      topMenuItems
        .reduce(
          (menuItems: WordPressMenuItem[], menuItem): WordPressMenuItem[] => {
            const mappedItem = mapQueriedMenuItem(menuItem);

            if (mappedItem) {
              menuItems.push(mappedItem);
            }

            return menuItems;
          },
          []
        )
        .sort(sortMenuItem),
    [topMenuItems]
  );
};
