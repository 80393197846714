import { Link } from "gatsby";
import React from "react";
import { VitisLogo } from "../../../__generated__/svgComponents/VitisLogo";
import { VTSLogoLinkProps } from "./VTSLogoLinkInterfaces";
import { useVTSLogoLinkStyles } from "./VTSLogoLinkStyles";

const VTSLogoLink = (props: VTSLogoLinkProps): JSX.Element => {
  const classes = useVTSLogoLinkStyles(props);

  return (
    <Link to="/" className={classes.link}>
      <VitisLogo className={classes.logo} />
    </Link>
  );
};

export default VTSLogoLink;
