import { uniqBy } from "lodash";
import { useMemo } from "react";
import { MenuItemType } from "../../../interfaces/MenuItemType";
import { WordPressMenuItem } from "../WordPressMenuItem";
import { useMenuItems } from "./useMenuItems";

export const useAllWordPressMenuItems = (): WordPressMenuItem[] => {
  const topMenuItems = useMenuItems(MenuItemType.Main);
  const subMenuItems = useMenuItems(MenuItemType.Sub);
  subMenuItems.forEach((subMenuItem) => {
    const parentMenuItem = topMenuItems.find(
      (menuItem) => menuItem.id === subMenuItem.parentId
    );

    if (parentMenuItem) {
      parentMenuItem.subMenuItems.push(subMenuItem);
    }
  });

  return useMemo(
    () => uniqBy(topMenuItems, (item) => item.path),
    [topMenuItems]
  );
};
