import { ReactNode } from "react";

export interface VTSAnimatedUnderlineProps {
  children?: ReactNode;
}

export interface VTSAnimatedUnderlineStyleProps {
  elementIsInView: boolean;
  scrollingDown: boolean;
}
