/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */

import { isObject } from "lodash";
import { AvailableWpMenuPostTypes } from "../../../interfaces/WordPress/availablePostTypes";

export const menuItemIsAnArchive = (
  menuItem:
    | GatsbyTypes.WpMenuItemCategoryFieldsFragment
    | GatsbyTypes.WpMenuItemPostFieldsFragment
): boolean => !menuItem.connectedNode;

export const menuItemIsTaxonomy = (
  menuItem:
    | GatsbyTypes.WpMenuItemCategoryFieldsFragment
    | GatsbyTypes.WpMenuItemPostFieldsFragment
): boolean => {
  const node = menuItem.connectedNode?.node;

  return isObject(node) && Object.hasOwnProperty.call(node, "taxonomy");
};

export const menuItemIsOfPostType = (
  menuItem:
    | GatsbyTypes.WpMenuItemCategoryFieldsFragment
    | GatsbyTypes.WpMenuItemPostFieldsFragment,
  postType: AvailableWpMenuPostTypes
): boolean => postType === menuItem.connectedNode?.node?.__typename;
