import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useVTSFooterNavBarStyles = makeStyles((theme: Theme) =>
  createStyles({
    navBar: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2, 0),
    },
    leftColumn: {
      justifyContent: "flex-start",
      columnGap: "3rem",
      [theme.breakpoints.only("md")]: {
        columnGap: "2rem",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    rightColumn: {
      justifyContent: "flex-end",
      columnGap: "3rem",
      [theme.breakpoints.only("md")]: {
        columnGap: "2rem",
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
      },
      [theme.breakpoints.only("xs")]: {
        flexDirection: "column",
        rowGap: "1rem",
      },
    },
  })
);
