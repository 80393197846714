import { Box, Typography, TypographyProps } from "@material-ui/core";
import { omit } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useMergedProps } from "../../../../hooks/useMergedProps";
import { NVONMenuItemTopLevelNavigationProps } from "./NVONMenuItemTopLevelNavigationInterfaces";
import { useNVONMenuItemTopLevelNavigationStyles } from "./NVONMenuItemTopLevelNavigationStyles";

const defaults: TypographyProps = {
  variant: "h6",
};

/**
 * A single menu item in the main menu top level
 */
const NVONMenuItemTopLevelNavigation = (
  props: NVONMenuItemTopLevelNavigationProps
): JSX.Element => {
  const [internalHoverState, setInternalHoverState] = useState<boolean>(false);
  const hoverOn = useRef(() => setInternalHoverState(true)).current;
  const hoverOff = useRef(() => setInternalHoverState(false)).current;

  // If we're not receiving the hover state as props, maintain it internally
  const useInternalState = props.isHovered === undefined;

  const classes = useNVONMenuItemTopLevelNavigationStyles({
    isHovered: useInternalState
      ? internalHoverState
      : (props.isHovered as boolean),
    textColor: props.textColor,
    isActivePage: props.isActivePage,
  });

  const mergedProps = useMergedProps(
    defaults,
    omit(props, "textColor", "isActivePage"),
    classes.typo
  );

  const typoProps = useMemo(
    () => omit(mergedProps, ["isHovered"]),
    [mergedProps]
  );

  return (
    <Box className={classes.wrapper}>
      <Typography
        onMouseEnter={hoverOn}
        onMouseLeave={hoverOff}
        {...typoProps}
      />

      <div className={classes.underline} />
    </Box>
  );
};

export default NVONMenuItemTopLevelNavigation;
