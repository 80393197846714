import { useMemo } from "react";
import { mapQueriedMenuItem } from "../../../helpers/clientSide/WordPress/mapQueriedMenuItem";
import { noNullNoUndefined } from "../../../helpers/general/noNullNoUndefined";
import { MenuItemType } from "../../../interfaces/MenuItemType";
import { sortMenuItem } from "../sortMenuItem";
import { WordPressMenuItem } from "../WordPressMenuItem";
import { useAllWordPressFooterMenuItemsQuery } from "./useAllWordPressFooterMenuItemsQuery";

export const useFooterMenuItems = (
  menuItemType: MenuItemType
): WordPressMenuItem[] => {
  const allMenuItems = useAllWordPressFooterMenuItemsQuery();

  const footerMenuItems = useMemo(
    () =>
      allMenuItems.allWpMenuItem.nodes.filter((item) =>
        menuItemType === MenuItemType.Sub ? item.parentId : !item.parentId
      ),
    [allMenuItems.allWpMenuItem.nodes, menuItemType]
  );

  return useMemo(
    () =>
      footerMenuItems
        .map(mapQueriedMenuItem)
        .filter(noNullNoUndefined)
        .sort(sortMenuItem),
    [footerMenuItems]
  );
};
