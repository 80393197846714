import { noop } from "lodash";
import { useEffect } from "react";
import { isBrowserEnvironment } from "../isBrowserEnvironment";

export const useWindowEvent: typeof window.addEventListener = (
  eventType: Parameters<typeof window.addEventListener>[0],
  callback: Parameters<typeof window.addEventListener>[1],
  options: Parameters<typeof window.addEventListener>[2]
) => {
  useEffect(() => {
    if (isBrowserEnvironment()) {
      window.addEventListener(eventType, callback, options);
      return () => {
        window.removeEventListener(eventType, callback);
      };
    }
    return noop; // Consistent return
  }, [callback, eventType, options]);
};
