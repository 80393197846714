import { WordPressMenuItem } from "../../../queries/wpMenuItems/WordPressMenuItem";
import { wordPressPagePathFromMenuItem } from "../../serverSide/wordPress/wordPressPagePathFromMenuItem";

export const mapQueriedMenuItem = (
  queriedItem: GatsbyTypes.WordpressMenusQuery["allWpMenuItem"]["nodes"][0]
): WordPressMenuItem | undefined => {
  const path = wordPressPagePathFromMenuItem(queriedItem);

  if (!path || !queriedItem.label || !queriedItem.order) {
    return undefined;
  }

  const connectedNode = queriedItem.connectedNode
    ?.node as GatsbyTypes.Maybe<GatsbyTypes.WpPageFieldsFragment>;

  return {
    id: queriedItem.id,
    label: queriedItem.label,
    path,
    order: queriedItem.order,
    parentId: queriedItem.parentId,
    subMenuItems: [],
    relatedPostId: connectedNode?.id || "",
  };
};
