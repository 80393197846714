import { createStyles, makeStyles } from "@material-ui/core";
import { PageLayoutProps } from "./PageLayoutInterfaces";

export const usePageLayoutStyles = makeStyles((theme) =>
  createStyles({
    layoutWrap: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      minHeight: "100%",
      width: "100%",
      position: "relative",
    },
    header: {
      position: "fixed",
      width: "100%",
    },
    main: (props: PageLayoutProps) => ({
      flexGrow: 1,
      backgroundColor: theme.palette.common.white,
      display: "flex",
      flexDirection: "column",
      justifyContent: props.centerContent ? "center" : "flex-start",
      alignItems: props.centerContent ? "center" : "flex-start",
      marginTop: theme.spacing(8),
    }),
  })
);
