import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationDe from "./locales/de.json";

export const resources = {
  de: { translation: translationDe },
};

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    saveMissing: true,
    fallbackLng: "de",
    resources,
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
