import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

const underlineSize = 3;
const textToUnderlineSpacing = 8;

export const useNVONDrawerNavMenuStyles = makeStyles((theme: Theme) => {
  const linkStyles: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-start",
    // You can configure hover and active of the links here
    // "& .MuiTypography-root:hover": {},
    // "& .MuiTypography-root:active": {},
    //
  };
  const topLevelItemStyles: CSSProperties = {
    margin: theme.spacing(0, 0, 3, 0),
    "&:first-child": {
      marginTop: 0,
    },
  };
  return createStyles({
    drawer: {
      "& .MuiDrawer-paper": {
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.default,
      },
      "& .active": {
        "& $label": {
          marginBottom: textToUnderlineSpacing,
          fontWeight: theme.typography.fontWeightBold,
        },
        "& $underline": {
          display: "block",
        },
      },
    },
    nav: {
      display: "flex",
      flexDirection: "column",
    },
    linkTop: {
      ...linkStyles,
      ...topLevelItemStyles,
      color: theme.palette.grey[900],
    },
    label: {
      marginBottom: underlineSize + textToUnderlineSpacing,
    },
    underline: {
      height: underlineSize,
      backgroundColor: theme.palette.secondary.contrastText,
      display: "none",
    },
  });
});
