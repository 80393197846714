import { ReactNode } from "react";
import { PostTypeInProject } from "../../../config/wordPressConfig";

export interface PageLayoutProps {
  children: ReactNode;
  currentPostId: string | GatsbyTypes.Maybe<string>;
  postType: PostTypeInProject;
  centerContent?: true;
  className?: string;
}
