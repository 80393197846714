import { useMemo } from "react";
import { mergeDefaultPropsAndClass } from "../helpers/clientSide/mergeDefaultPropsAndClass";
import { CanHaveClassName } from "../interfaces/PropsAndComponents";

// Overloads for making it possible to merge partials and full interfaces
// in every possible combination
export function useMergedProps<T extends CanHaveClassName>(
  defaultProps: T | undefined,
  props: T | undefined,
  className?: string | undefined
): T;

export function useMergedProps<T extends CanHaveClassName>(
  defaultProps: Partial<T> | undefined,
  props: T | undefined,
  className?: string | undefined
): T;

export function useMergedProps<T extends CanHaveClassName>(
  defaultProps: T | undefined,
  props: Partial<T> | undefined,
  className?: string | undefined
): T;

/**
 * Merges a set of default values with values passed as props.
 * Additionally, a className (e.g. from `makeStyles`) can be passed.
 * While props will override defaults, classNames will be added.
 *
 * Props passed as parameters need to explicitly include className, at least optionally.
 */
export function useMergedProps<T extends CanHaveClassName>(
  defaultProps: Partial<T> | undefined,
  props: Partial<T> | undefined,
  className?: string | undefined
): Partial<T> {
  return useMemo(
    () => mergeDefaultPropsAndClass(defaultProps, props, className),
    [defaultProps, props, className]
  );
}
