import { CanHaveClassName } from "../../../interfaces/PropsAndComponents";

export interface MenuItem {
  href: string;
  title: string;
  label: string;
  childItems?: MenuItem[];
}

export interface NVONHorizontalNavMenuProps extends CanHaveClassName {
  textColor: string;
  activePageId: string;
}
