import { Box, Container } from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { useDocumentScrollPosition } from "../../../helpers/clientSide/hooks/useDocumentScrollPosition";
import VTSLogoLink from "../../01-atoms/VTSLogoLink/VTSLogoLink";
import NVONDrawerNavMenu from "../NVONDrawerNavMenu/NVONDrawerNavMenu";
import NVONHorizontalNavMenu from "../NVONHorizontalNavMenu/NVONHorizontalNavMenu";
import { NVONHeaderProperties } from "./NVONHeaderInterfaces";
import { useNVONHeaderStyles } from "./NVONHeaderStyles";

const NVONHeader = (props: NVONHeaderProperties): JSX.Element => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const classes = useNVONHeaderStyles({
    isScrolled,
    textColor: props.textColor,
  });
  const { textColor } = props;
  useDocumentScrollPosition((position) => {
    setIsScrolled(position > 20);
  });

  const desktopMenu = (
    <NVONHorizontalNavMenu
      key="desktopMenu"
      className={classes.desktopMenu}
      textColor={textColor}
      activePageId={props.currentPostId || ""}
    />
  );

  const mobileMenu = (
    <NVONDrawerNavMenu
      key="mobileMenu"
      wrapperClassName={classes.mobileMenu}
      color={textColor}
      activePageId={props.currentPostId || ""}
    />
  );

  return (
    <Box
      component="header"
      className={clsx(props.className, classes.headerWrapper)}
    >
      <Container className={clsx(classes.contentWrapper, "noMargin")}>
        <VTSLogoLink color={textColor} />

        {mobileMenu}

        {desktopMenu}
      </Container>
    </Box>
  );
};

export default NVONHeader;
