import {
  AppBar,
  Button,
  Container,
  IconButton,
  SvgIcon,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import PhoneEnabledIcon from "@material-ui/icons/PhoneEnabled";
import RoomIcon from "@material-ui/icons/Room";
import React from "react";
import { useWpVitisSettings } from "../../../queries/wpVitisSettings/useWpVitisSettings";
import { useVTSSubNavigationStyles } from "./VTSSubNavigationStyles";

const VTSSubNavigation = (): JSX.Element => {
  const classes = useVTSSubNavigationStyles();
  const infoData = useWpVitisSettings()?.vitisFields;
  const city = infoData?.address?.city;
  const phone = infoData?.contact?.landlinePhoneNumber;
  const linkedInLink = infoData?.contact?.linkedinLink;
  const xingLink = infoData?.contact?.xingLink;
  const email = infoData?.contact?.emailAddress;
  return (
    <AppBar position="static" className={classes.wrapper}>
      <Container className="noMargin">
        <Toolbar className={classes.toolbar} disableGutters={true}>
          <Button
            color="inherit"
            className={classes.links}
            href="https://goo.gl/maps/3a2sMwc5oYJfi44x8"
            target="_blank"
          >
            <RoomIcon className={classes.icon} />

            <Typography variant="body2" className={classes.linkText}>
              {city}
            </Typography>
          </Button>

          <Button
            href={`tel:${phone}`}
            color="inherit"
            className={classes.links}
            target="_blank"
          >
            <PhoneEnabledIcon className={classes.icon} />

            <Typography variant="body2" className={classes.linkText}>
              {phone}
            </Typography>
          </Button>

          <Button
            href={`mailto:${email}`}
            color="inherit"
            className={classes.links}
            target="_blank"
          >
            <MailIcon className={classes.icon} />

            <Typography variant="body2" className={classes.linkText}>
              {email}
            </Typography>
          </Button>

          {linkedInLink !== undefined ? (
            <IconButton
              color="default"
              className={classes.links}
              target="_blank"
              href={linkedInLink ?? ""}
            >
              <SvgIcon className={classes.icon}>
                <path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z" />
              </SvgIcon>
            </IconButton>
          ) : (
            ""
          )}

          {xingLink !== undefined ? (
            <IconButton
              color="default"
              className={classes.links}
              target="_blank"
              href={xingLink ?? ""}
            >
              <SvgIcon className={classes.icon}>
                <path d="M18.188 0c-.517 0-.741.325-.927.66 0 0-7.455 13.224-7.702 13.657.015.024 4.919 9.023 4.919 9.023.17.308.436.66.967.66h3.454c.211 0 .375-.078.463-.22.089-.151.089-.346-.009-.536l-4.879-8.916c-.004-.006-.004-.016 0-.022L22.139.756c.095-.191.097-.387.006-.535C22.056.078 21.894 0 21.686 0h-3.498zM3.648 4.74c-.211 0-.385.074-.473.216-.09.149-.078.339.02.531l2.34 4.05c.004.01.004.016 0 .021L1.86 16.051c-.099.188-.093.381 0 .529.085.142.239.234.45.234h3.461c.518 0 .766-.348.945-.667l3.734-6.609-2.378-4.155c-.172-.315-.434-.659-.962-.659H3.648v.016z" />
              </SvgIcon>
            </IconButton>
          ) : (
            ""
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default VTSSubNavigation;
