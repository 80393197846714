import { createStyles, makeStyles } from "@material-ui/core";
import { VTSLogoLinkProps } from "./VTSLogoLinkInterfaces";

export const useVTSLogoLinkStyles = makeStyles(() =>
  createStyles({
    link: {
      textDecoration: `none`,
      height: "3.5rem",
    },
    logo: {
      alignSelf: "flex-start",
      height: "100%",
      width: "auto",
      "& #text": {
        "& path": {
          fill: (props: VTSLogoLinkProps) => props.color,
          color: (props: VTSLogoLinkProps) => props.color,
        },
      },
    },
  })
);
