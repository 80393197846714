import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useVTSFloatingCommunicationBubbleStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        position: "fixed",
        right: 0,
        bottom: 0,
        zIndex: 200,
      },
      speedDial: {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
      speedDialIcon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      speedDialAction: {
        "&.MuiSpeedDialAction-fab": {
          color: theme.palette.secondary.dark,
        },
      },
    })
);
