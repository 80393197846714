import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import MailIcon from "@material-ui/icons/Mail";
import PhoneEnabledIcon from "@material-ui/icons/PhoneEnabled";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isBrowserEnvironment } from "../../../helpers/clientSide/isBrowserEnvironment";
import { useWpVitisSettings } from "../../../queries/wpVitisSettings/useWpVitisSettings";
import {
  ContactOptionType,
  VTSFloatingCommunicationBubbleProps,
} from "./VTSFloatingCommunicationBubbleInterfaces";
import { useVTSFloatingCommunicationBubbleStyles } from "./VTSFloatingCommunicationBubbleStyles";

const VTSFloatingCommunicationBubble = (
  props: VTSFloatingCommunicationBubbleProps
): JSX.Element => {
  const classes = useVTSFloatingCommunicationBubbleStyles(props);
  const { t } = useTranslation();
  const theme = useTheme();
  const bigViewport = useMediaQuery(theme.breakpoints.up("md"));

  // Data
  const contactData = useWpVitisSettings()?.vitisFields?.contact;
  const phoneNumber = contactData?.landlinePhoneNumber;
  const emailAddress = contactData?.emailAddress;
  const linkedinLink = contactData?.linkedinLink;

  // Maps
  const mapContactName: { [key in ContactOptionType]: string } = {
    [ContactOptionType.Email]: `${t("floatingCommunicationBubble.email")}`,
    [ContactOptionType.Telephone]: `${t(
      "floatingCommunicationBubble.telephone"
    )}`,
    [ContactOptionType.LinkedIn]: `${t(
      "floatingCommunicationBubble.linkedin"
    )}`,
  };

  const mapContactIcon: { [key in ContactOptionType]: JSX.Element } = {
    [ContactOptionType.Email]: <MailIcon />,
    [ContactOptionType.Telephone]: <PhoneEnabledIcon />,
    [ContactOptionType.LinkedIn]: <LinkedInIcon />,
  };

  const mapOnClick: { [key in ContactOptionType]: VoidFunction } = {
    [ContactOptionType.Email]: () => {
      if (isBrowserEnvironment()) {
        window.open(`mailto:${emailAddress}`);
      }
      handleClose();
    },
    [ContactOptionType.Telephone]: () => {
      if (isBrowserEnvironment()) {
        window.open(`tel:${phoneNumber}`);
      }
      handleClose();
    },
    [ContactOptionType.LinkedIn]: () => {
      if (isBrowserEnvironment()) {
        window.open(linkedinLink);
      }
      handleClose();
    },
  };

  // Open/close SpeedDial
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleHidden = useMemo(() => {
    if (props.inView === true) {
      if (bigViewport) {
        return true;
      }
      return false;
    }
    return false;
  }, [bigViewport, props.inView]);

  return (
    <Box className={classes.root}>
      <SpeedDial
        ariaLabel="help"
        direction="up"
        className={classes.speedDial}
        icon={
          <SpeedDialIcon
            icon={<LiveHelpIcon fontSize="large" />}
            openIcon={<LiveHelpIcon fontSize="large" />}
            className={classes.speedDialIcon}
          />
        }
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        hidden={handleHidden}
      >
        {Object.values(ContactOptionType).map((contactOption) => (
          <SpeedDialAction
            key={mapContactName[contactOption]}
            icon={mapContactIcon[contactOption]}
            tooltipTitle={mapContactName[contactOption]}
            onClick={mapOnClick[contactOption]}
            tooltipPlacement="left"
            className={classes.speedDialAction}
          />
        ))}
      </SpeedDial>
    </Box>
  );
};

export default VTSFloatingCommunicationBubble;
