import { createStyles, makeStyles } from "@material-ui/core";
import theme from "../../../layouts/theme/theme";

export const useNVONHorizontalNavMenuStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      "& > $navigationItem:not(:last-child)": {
        marginRight: theme.spacing(4),
      },
    },
    navigationItem: {},
  })
);
