/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */

import { PostTypeInProject } from "../../../../config/wordPressConfig";
import { pathForSinglePost } from "../pageCreation/pathForSinglePost";
import { archiveSlugFromUrl } from "./postTypeFromArchiveUrl";
import { menuItemIsAnArchive } from "./wordPressMenuTypes";

export const wordPressPagePathFromMenuItem = (
  menuItem:
    | GatsbyTypes.WpMenuItemCategoryFieldsFragment
    | GatsbyTypes.WpMenuItemPostFieldsFragment
): string | undefined => {
  const node = menuItem.connectedNode?.node;

  if (menuItemIsAnArchive(menuItem)) {
    if (!menuItem.url) {
      return undefined;
    }

    return `/${archiveSlugFromUrl(menuItem.url)}`;
  }

  if (!node?.slug) {
    return undefined;
  }

  return pathForSinglePost(
    node?.__typename as PostTypeInProject,
    node?.slug || ""
  );
};
