import { createStyles, makeStyles } from "@material-ui/core";
import { NVONHeaderStylesProps } from "./NVONHeaderInterfaces";

export const useNVONHeaderStyles = makeStyles((theme) => {
  const color = theme.palette.primary.contrastText;
  const transitionProperties: string[] = ["padding", "box-shadow"];

  return createStyles({
    headerWrapper: {
      zIndex: 200,
      padding: ({ isScrolled }: NVONHeaderStylesProps) =>
        isScrolled ? theme.spacing(1, 0) : theme.spacing(2, 0),
      background: theme.palette.background.default,
      transition: theme.transitions.create(
        ["background", ...transitionProperties],
        {
          duration: theme.transitions.duration.complex,
          easing: theme.transitions.easing.easeOut,
        }
      ),
      boxShadow: ({ isScrolled }: NVONHeaderStylesProps) => {
        return isScrolled ? `0px 4px 6px ${theme.palette.grey[300]}` : "none";
      },
      [theme.breakpoints.up("md")]: {
        paddingTop: ({ isScrolled }: NVONHeaderStylesProps) => {
          return isScrolled ? theme.spacing(1) : theme.spacing(4);
        },
        transition: theme.transitions.create(
          ["paddingTop", ...transitionProperties],
          {
            duration: theme.transitions.duration.complex,
            easing: theme.transitions.easing.easeOut,
          }
        ),
      },
    },
    contentWrapper: {
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& a": {
        color,
      },
      "& .hamburger-react": {
        color,
      },
    },
    mobileMenu: {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    desktopMenu: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  });
});
