import { uniqBy } from "lodash";
import { useMemo } from "react";
import { MenuItemType } from "../../../interfaces/MenuItemType";
import { WordPressMenuItem } from "../WordPressMenuItem";
import { useFooterMenuItems } from "./useFooterMenuItems";

export const useAllWordPressFooterMenuItems = (): WordPressMenuItem[] => {
  const footerMenuItems = useFooterMenuItems(MenuItemType.Main);

  return useMemo(
    () => uniqBy(footerMenuItems, (item) => item.path),
    [footerMenuItems]
  );
};
