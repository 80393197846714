import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useVTSFooterStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      display: "flex",
      flexDirection: "column",
    },
    card: {
      position: "absolute",
      margin: "auto",
      left: "50%",
      marginLeft: theme.spacing(-7.75),
      marginTop: theme.spacing(-3.25),
      zIndex: 2,
    },
    info: {
      position: "relative",
      backgroundColor: theme.palette.secondary.main,
      paddingTop: theme.spacing(6.25),
      paddingBottom: theme.spacing(8.75),
      overflow: "hidden",
      [theme.breakpoints.down("md")]: {
        paddingBottom: theme.spacing(6.25),
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    logoBackground: {
      width: "50%",
      position: "absolute",
      top: theme.spacing(-4),
      right: theme.spacing(-4),
      [theme.breakpoints.only("md")]: {
        width: "60%",
      },
      [theme.breakpoints.only("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.only("xs")]: {
        top: theme.spacing(2),
        width: "120%",
      },
    },
    infoContentWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    slogan: {
      position: "relative",
    },
    title: {
      marginBottom: 0,
      fontWeight: theme.typography.fontWeightBold,
    },
    handWrittenSlogan: {
      textAlign: "left",
      marginTop: theme.spacing(-1.5),
      marginLeft: theme.spacing(12),
      marginBottom: 0,
      [theme.breakpoints.only("sm")]: {
        marginLeft: theme.spacing(10),
      },
      [theme.breakpoints.only("xs")]: {
        marginTop: theme.spacing(-1),
        marginLeft: 0,
      },
    },
    details: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      zIndex: 1,
      [theme.breakpoints.only("md")]: {
        flexDirection: "column",
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(4.5),
      },
      [theme.breakpoints.only("xs")]: {
        flexDirection: "column",
      },
    },
    contact: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: theme.spacing(3),
      [theme.breakpoints.only("md")]: {
        paddingLeft: 0,
        paddingTop: theme.spacing(2),
      },
      [theme.breakpoints.only("xs")]: {
        paddingLeft: 0,
        paddingTop: theme.spacing(2),
      },
      "& > *": {
        justifyContent: "start",
      },
    },
    link: {
      cursor: "pointer",
      textTransform: "lowercase",
      padding: 0,
    },
  })
);
