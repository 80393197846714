import { Drawer, Typography } from "@material-ui/core";
import clsx from "clsx";
import { Link } from "gatsby-material-ui-components/lib/link";
import Hamburger from "hamburger-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useBoolean } from "use-boolean";
import { useAllWordPressMenuItems } from "../../../queries/wpMenuItems/header/useAllWordPressMenuItems";
import { NVONDrawerNavMenuProps } from "./NVONDrawerNavMenuInterfaces";
import { useNVONDrawerNavMenuStyles } from "./NVONDrawerNavMenuStyles";

const NVONDrawerNavMenu = (props: NVONDrawerNavMenuProps): JSX.Element => {
  const classes = useNVONDrawerNavMenuStyles();
  const [menuIsOpen, , onClose, toggle] = useBoolean(false);
  const menuItems = useAllWordPressMenuItems();
  const { t } = useTranslation();

  return (
    <span className={props.wrapperClassName}>
      <Hamburger
        label={t("header.mobileMenu.burgerIconLabel")}
        toggled={menuIsOpen}
        toggle={toggle}
        color={props.color}
      />

      <Drawer
        anchor="left"
        className={classes.drawer}
        open={menuIsOpen}
        onClose={onClose}
      >
        <Typography variant="h6" component="nav" className={classes.nav}>
          {menuItems.map((menuItem) => {
            const isActivePage = menuItem.relatedPostId === props.activePageId;
            return (
              <Link
                className={clsx(
                  classes.linkTop,
                  isActivePage ? "active" : undefined
                )}
                underline="none"
                to={menuItem.path}
                key={menuItem.id}
              >
                <Typography
                  variant="h1"
                  component="span"
                  className={classes.label}
                >
                  {menuItem.label}
                </Typography>

                <div className={classes.underline} />
              </Link>
            );
          })}
        </Typography>
      </Drawer>
    </span>
  );
};

export default NVONDrawerNavMenu;
