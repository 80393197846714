import { useMemo } from "react";
import sanitizeHtml from "sanitize-html";
import { UseSanitizedHtmlProps } from "./UseSanitizedHtmlInterface";

const defaultOptions: sanitizeHtml.IOptions = {
  allowedTags: ["b", "i", "em", "strong", "a", "br", "p"],
  allowedAttributes: {
    a: ["href", "target"],
  },
};

export const useSanitizedHtml = (props: UseSanitizedHtmlProps): string => {
  const sanitizedHtml = useMemo(
    () =>
      props.html
        ? sanitizeHtml(props.html, {
            ...defaultOptions,
            ...props.options,
          })
        : "",
    [props.options, props.html]
  );

  return sanitizedHtml;
};
