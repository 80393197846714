import { graphql, useStaticQuery } from "gatsby";

export const useAllWordPressMenuItemsQuery =
  (): GatsbyTypes.WordpressMenusQuery =>
    useStaticQuery<GatsbyTypes.WordpressMenusQuery>(graphql`
      query WordpressMenus {
        allWpMenuItem(
          filter: { menu: { node: { locations: { eq: GATSBY_HEADER_MENU } } } }
        ) {
          nodes {
            ...WpMenuItemPostFields
            ...WpMenuItemCategoryFields
          }
        }
      }
    `);
