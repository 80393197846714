import "@fontsource/caveat/400.css";
import "@fontsource/dm-sans/400.css";
import "@fontsource/dm-sans/500.css";
import "@fontsource/dm-sans/700.css";
import { useTheme } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import NVONHeader from "../../components/03-organisms/NVONHeader/NVONHeader";
import VTSFooter from "../../components/03-organisms/VTSFooter/VTSFooter";
import VTSFooterNavBar from "../../components/03-organisms/VTSFooterNavBar/VTSFooterNavBar";
import VTSSubNavigation from "../../components/03-organisms/VTSSubNavigation/VTSSubNavigation";
import "../../i18n/i18n";
import { PageLayoutProps } from "./PageLayoutInterfaces";
import { usePageLayoutStyles } from "./usePageLayoutStyles";

export const PageLayout = (props: PageLayoutProps): JSX.Element => {
  const theme = useTheme();
  const classes = usePageLayoutStyles(props);

  return (
    <div className={classes.layoutWrap}>
      <Helmet>
        <html lang="de" />
      </Helmet>

      <VTSSubNavigation />

      <NVONHeader
        className={classes.header}
        currentPostId={props.currentPostId}
        textColor={theme.palette.text.primary}
      />

      <main className={classes.main}>{props.children}</main>

      <VTSFooter />

      <VTSFooterNavBar />
    </div>
  );
};
