import clsx from "clsx";
import { Link } from "gatsby-material-ui-components/lib/link";
import React, { useMemo } from "react";
import { useAllWordPressMenuItems } from "../../../queries/wpMenuItems/header/useAllWordPressMenuItems";
import { NVONHorizontalNavMenuProps } from "./NVONHorizontalNavMenuInterfaces";
import { useNVONHorizontalNavMenuStyles } from "./NVONHorizontalNavMenuStyles";
import NVONMenuItemTopLevelNavigation from "./NVONMenuItemTopLevelNavigation/NVONMenuItemTopLevelNavigation";

const NVONHorizontalNavMenu = (
  props: NVONHorizontalNavMenuProps
): JSX.Element => {
  const menuItems = useAllWordPressMenuItems();
  const classes = useNVONHorizontalNavMenuStyles();

  const mainMenuItems = useMemo(
    () =>
      menuItems.map((mainMenuItem) => {
        return (
          <Link
            underline="none"
            to={mainMenuItem.path}
            key={mainMenuItem.id}
            className={classes.navigationItem}
          >
            <NVONMenuItemTopLevelNavigation
              isActivePage={mainMenuItem.relatedPostId === props.activePageId}
              textColor={props.textColor}
              variant="body1"
            >
              {mainMenuItem.label}
            </NVONMenuItemTopLevelNavigation>
          </Link>
        );
      }),
    [menuItems, props.textColor, props.activePageId, classes.navigationItem]
  );

  return (
    <nav className={clsx(classes.wrapper, props.className)}>
      {mainMenuItems}
    </nav>
  );
};

export default NVONHorizontalNavMenu;
