import { Element } from "domhandler/lib/node";
import HTMLReactParser, { domToReact } from "html-react-parser";
import React, { FunctionComponent } from "react";

// As of 13.07.21:
// Disclaimer: This function has not been extensively tested yet.
// Use with caution, especially when trying to replace tags in nested hierarchies.
export const replaceTagsWithFunctionComponent = (
  html: string,
  oldTag: keyof JSX.IntrinsicElements,
  NewTagFunctionComponent: FunctionComponent,
  functionComponentProps?: { [key: string]: string }
): string | JSX.Element | JSX.Element[] =>
  HTMLReactParser(html, {
    replace: (domNode) => {
      if (
        domNode instanceof Element &&
        domNode.attribs &&
        domNode.name === oldTag
      ) {
        return (
          <NewTagFunctionComponent {...functionComponentProps}>
            {domToReact(domNode.children)}
          </NewTagFunctionComponent>
        );
      }
      return undefined;
    },
  });
