import { alpha, createStyles, makeStyles, Theme } from "@material-ui/core";
import { NVONMenuItemTopLevelNavigationProps } from "./NVONMenuItemTopLevelNavigationInterfaces";

const underlineSize = 2;
const textToUnderlineSpacing = 3;

export const useNVONMenuItemTopLevelNavigationStyles = makeStyles(
  (theme: Theme) => {
    const size = 0.2;
    return createStyles({
      wrapper: {
        justifyContent: "center",
        alignItems: "center",
      },
      typo: {
        transition: "text-shadow 0.2s ease-in-out",
        color: ({ textColor }: NVONMenuItemTopLevelNavigationProps) =>
          textColor,
        textShadow: ({
          isHovered,
          textColor,
          isActivePage,
        }: NVONMenuItemTopLevelNavigationProps) =>
          isHovered || isActivePage
            ? `
          -${size}px -${size}px 0 ${alpha(textColor, 0.4)},
          ${size}px -${size}px 0 ${alpha(textColor, 0.4)},
          -${size}px ${size}px 0 ${alpha(textColor, 0.4)},
          ${size}px ${size}px 0 ${alpha(textColor, 0.4)}`
            : "none",
        marginBottom: ({ isActivePage }: NVONMenuItemTopLevelNavigationProps) =>
          isActivePage
            ? textToUnderlineSpacing
            : underlineSize + textToUnderlineSpacing,
      },
      underline: {
        height: underlineSize,
        width: "70%",
        minWidth: 30,
        backgroundColor: theme.palette.secondary.contrastText,
        margin: "auto",
        display: ({ isActivePage }: NVONMenuItemTopLevelNavigationProps) =>
          isActivePage ? undefined : "none",
      },
    });
  }
);
